<template>
  <div class="dc" v-wechat-title="$route.meta.title">
    <navigation :msg="msg" />
    <div class="buclass">
      <button @click="menuindex = 1" :id="menuindex == 1 ? 'bu1' : 'bu3'">
        短信授权
      </button>
      <button @click="menuindex = 3" :id="menuindex == 3 ? 'bu4' : 'bu2'">
        二维码授权
      </button>
    </div>
    <div v-show="menuindex == 3" class="sq">
      <p>
        二维码授权使用说明：创建二维码→将二维码发送至候选人→候选人扫码填写个人信息、完成授权(及支付)→委托方查看背调报告；
        此模式支持“一码对多人”，适合批量型背调；
        二维码使用和管理规则详见“(底部菜单栏)二维码管理”
      </p>
    </div>
    <div v-show="menuindex != 3" class="sq">
      <p>
        短信授权说明：授权确认会以短信形式发送至候选人本人，请确保候选人信号畅通；候选人同意授权后，委托方即可至“(底部菜单栏)背调记录"查看报告；
      </p>
    </div>
    <div class="writetipe">
      <img src="../../assets/redBar.png" />
      <p>填写被调人信息</p>
    </div>
    <div class="pcla">
      <p><span>*</span>套餐名称</p>
      <p>
        {{ $route.params.id == 4 ? "自定义套餐" : taomsg.cname }}
      </p>
    </div>
    <div v-show="menuindex != 3" class="pcla">
      <p><span>*</span>手机</p>
      <p>
        <input
          v-model="obj.mobile"
          class="inclass"
          placeholder="授权信息将发送至此手机号"
        />
      </p>
    </div>
    <div v-show="menuindex != 3" class="pcla">
      <p>身份证</p>
      <p>
        <input
          v-model="obj.idNo"
          class="inclass"
          placeholder="如委托方未填，请告知候选人必填"
        />
      </p>
    </div>
    <div v-show="menuindex != 3" class="pcla">
      <p>姓名</p>
      <p>
        <input
          v-model="obj.name"
          class="inclass"
          placeholder="委托方未填，请告知候选人必填"
        />
      </p>
    </div>
    <div v-show="menuindex == 3" class="pcla">
      <p><span>*</span>二维码名称</p>
      <p>
        <input
          v-model="obj.qr_name"
          class="inclass"
          placeholder="XX门店或XXX岗位"
        />
      </p>
    </div>

    <div v-show="menuindex == 3" class="pcla">
      <p><span>*</span>功能状态</p>
      <div class="qiyongclass" @click="show = true">
        <div>{{ okno }}</div>
        <van-icon name="arrow-down" />
      </div>
    </div>

    <div v-show="menuindex != 3 && isxueli == true" class="pcla">
      <p>学历编号</p>
      <p>
        <input
          v-model="certId"
          class="inclass"
          placeholder="如委托方未填，请告知候选人必填"
        />
      </p>
    </div>
    <!-- <div v-if="$route.params.id == 4" class="pcla">
      <p>资格证书</p>
      <p><input class="inclass" placeholder="请输入资格证书" /></p>
    </div>
    <div v-if="$route.params.id == 4" class="pcla">
      <p>护照编码</p>
      <p><input class="inclass" placeholder="请输入护照编码" /></p>
    </div> -->
    <div class="proclassall" v-show="menuindex != 3 && payindex == 4">
      <div class="proclass">
        <div class="baoxian">
          <van-checkbox
            checked-color="#1677FF"
            v-model="checked"
          ></van-checkbox>
          <label class="labelclass">购买萝卜猎手雇佣保障方案</label>
        </div>
        <p class="baoxianmoney">
          <span>¥</span><span>{{ baoseemoney }}</span>
        </p>
      </div>
      <div class="tipclass">注：若该候选人背调后3月内从贵司离职可申请理赔</div>
      <div @click="show3 = true" class="pro2cl">
        <div></div>
        <div>
          <p>方案说明</p>
          <div class="xiangxi">
            <div>详细</div>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <div class="payclass">
      <div>
        <p><span>*</span>选择付款方</p>
      </div>
      <div>
        <button @click="payindex = 6" :id="payindex == 6 ? 'mepay' : 'qipay'">
          候选人支付
        </button>
        <button @click="payindex = 4" :id="payindex == 4 ? 'mepay' : 'qipay'">
          委托方支付
        </button>
      </div>
    </div>

    <div class="sp">
      <p>
        委托方支付:委托方发起授权时从委托方企业账户直接扣款;候选人支付:委托方无须支付,候选人收到授权信息后自行完成支付;
      </p>
    </div>
    <div class="writetipe selectclass">
      <img src="../../assets/redBar.png" />
      <p>选择项目</p>
    </div>

    <div class="sfcl">
      <div
        @click="selectmsg(item, index)"
        v-for="(index, item) in taomsg.set_id"
        v-bind:key="item.id"
        :class="
          $route.params.id == 4
            ? newArray[item] == item
              ? 'selecl'
              : ''
            : 'selecl'
        "
      >
        <p>{{ index.name }}</p>
        <p>¥ {{ index.price }}</p>
      </div>
    </div>
    <div class="moneypay">
      <div>
        <p>项目价格：</p>
        <p>¥ {{ mo }}</p>
      </div>
      <div>
        <button @click="tobackcenter" id="fqclass">
          {{ menuindex != 3 ? "发起授权" : "创建二维码" }}
        </button>
      </div>
    </div>
    <div style="height: 2.1rem"></div>

    <!-- 弹出层 -->
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    <!-- <van-action-sheet v-show="show" :actions="actions" @select="onSelect" /> -->
    <terms class="temsclass" v-show="show3 == true"></terms>
  </div>
</template>
<script>
import terms from "../../views/terms/index";
import base from "../../../util/base";
import Vue from "vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      protocol: "",
      msg: "发起背调授权",
      menuindex: 1, //1:短信授权 3：二维码授权
      payindex: 4, //4：委托方支付 6：候选人支付
      taomsg: "", //套餐信息
      obj: {
        idNo: "",
        name: "",
        qr_name: "", //二维码名称
      },
      mo: 0,
      newArray: [],
      selfBuilt: 6, //是否是自建套餐  1否 2是
      accredit: "", // 授权方式  1短信授权、2上传授权书 3、生物授权
      setId: [], // 具体接口
      url: "", //服务器地址
      show: false,
      actions: [{ name: "启用" }, { name: "禁用" }],
      okno: "启用", //启用还是禁用二维码
      status: 1, // 状态 1-开启  0-关闭
      checked: true, //是否勾选协议
      baocount: 0, //已购买的保险次数
      baoseemoney: "0.00", //保险展示价格
      baoxianlength: "",
      show3: false, //是否展示保险业务
      msg2: "条款内容",
      certId: "", //学历编号
      isxueli: false, //是否包含学历
    };
  },
  components: {
    terms,
  },
  watch: {
    menuindex(oldmsg, nowmsg) {
      if (nowmsg == "1") {
        this.payindex = 6;
      } else {
        this.payindex = 4;
      }
    },
  },

  async mounted() {
    // 查询保险次数
    this.oldmoney();

    this.url = base.url;
    // 监听是否是从创建二维码进入
    if (this.$route.params.isqrcode == "true") {
      this.menuindex = 3;
    }
    if (this.$route.params.taomsg == undefined) {
      this.$router.push("/backto");
      return;
    }
    this.taomsg = this.$route.params.taomsg;

    /**判断是否包含学历 */
    this.ishavexueli();

    await this.allmoney();
  },

  methods: {
    /**
     * 查询是否包含学历编号
     */
    ishavexueli() {
      for (let i = 0; i < this.taomsg.set_id.length; i++) {
        if (this.taomsg.set_id[i].api_name == "educationInfo_query") {
          this.isxueli = true;
        }
      }
    },
    // parentmeth(){
    //   this.show3 = false
    // },
    fatherMethod() {
      this.show3 = false;
    },
    /**
     * 查询保险次数
     */
    selectbao() {
      var that = this;
      return new Promise(function (resolve, reject) {
        that.$http
          .post("/firm/v1/Background/buyInsuranceCount", {
            reqType: "Background",
          })
          .then((res) => {
            that.baocount = JSON.parse(res.data).data.count;
            resolve();
          });
      });
    },

    /**
     * 最终金额计算（含保险）
     */
    async oldmoney() {
      await this.selectbao();

      if (parseInt(this.baocount) > 10) {
        this.checked = false; //取消自动勾选保险业务
      }
    },

    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.okno = item.name;
      if (this.okno == "启用") {
        this.status = 1;
      } else {
        this.status = 0;
      }
      //
    },
    selectmsg(index, item) {
      if (this.$route.params.id == 4) {
        if (this.newArray[index] == index) {
          Vue.set(this.newArray, index, null);
          this.mo -= parseInt(item.price);
          this.setId = this.setId.filter((res) => res != item.id);
        } else {
          Vue.set(this.newArray, index, index);
          this.mo += parseInt(item.price);
          this.setId.push(item.id);
        }
      }
    },
    allmoney() {
      if (this.$route.params.id == 4) {
        return;
      } else {
        this.mo = 0;
        let aaa = this.taomsg.set_id;

        this.mo = this.$route.params.taomsg.price;
        for (let i = 0; i < aaa.length; i++) {
          // this.mo += parseInt(aaa[i].price);
          this.setId.push(aaa[i].id);
        }
        return this.mo;
      }
    },

    qrback() {
      if (this.obj.qr_name == "") {
        this.$toast("请输入二维码名称");
        return;
      }

      this.$toast.loading();
      this.$http
        .post("/firm/v1/Background/add3", {
          reqType: "Background",
          l_id: "",
          pay_type: this.payindex, //个人支付 1\2\3消耗数量   4金额 6个人支付
          num: this.mo, //金额
          set_id: this.setId, //具体产品信息 数组
          accredit: this.menuindex, // 授权方式  1短信授权、2上传授权书 3、生物授权
          product_edition:
            this.$route.params.id == 4 ? "自定义套餐" : this.taomsg.cname, //套餐名称
          self_built: this.$route.params.id == 4 ? "2" : "1", //是否是自建套餐  1否 2是
          p_id: this.$route.params.taomsg.id, //商品 id
          status: this.status, // 状态 1-开启  0-关闭
          url: base.url + "/qrcode", //跳转到哪个页面
          qr_name: this.obj.qr_name, // 二维码名称
        })
        .then((res) => {
          let qrid = JSON.parse(res.data).data.qr_id;
          let base = window.btoa(qrid);
          this.$router.push({
            path: "qrcode",
            query: {
              qrId: base + "",
              company: JSON.parse(res.data).data.company, //公司名称
              qrName: this.obj.qr_name,
            },
          });
        });
    },

    tobackcenter() {
      if (
        this.checked == true &&
        this.$route.params.id != 4 &&
        parseInt(this.baocount) > 10 &&
        this.payindex == 4
      ) {
        Dialog.confirm({
          title: "提醒",
          message:
            "保险免费次数已用完，需要进行高级验证后才能勾选保险业务！请联系客服完成高级认证！",
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
        return;
      }
      /**
       * 自定义套餐时，需要判断是否选择套餐
       *
       */
      if (this.$route.params.id == 4) {
        if (this.setId.length < 1) {
          this.$toast("请选择项目");
          return;
        }
      }

      // 二维码授权
      if (this.menuindex == "3") {
        this.qrback();
        return;
      }
      if (this.menuindex != 3) {
        if (this.obj.mobile == "" || this.obj.mobile == undefined) {
          this.$toast("请输入手机号");
          return;
        }
        // else if (!/^1[3-9]\d{9}$/.test(this.obj.mobile)) {
        //   this.$toast("请输入正确手机号");
        //   return;
        // }
      }

      if (this.obj.name != "") {
        if (
          !/^[\u4e00-\u9fa5]+$/gi.test(this.obj.name) ||
          (this.obj.name + "").length < 1
        ) {
          this.$toast("请输入正确姓名");
          return;
        }
      }

      this.$toast.loading();

      /**
       * 手机，身份证，姓名，套餐名称，学历编号.
       */
      this.$http
        .post("/firm/v1/Background/add1", {
          reqType: "Background",
          name: this.obj.name, //姓名
          id_no: this.obj.idNo, //身份证号
          mobile: this.obj.mobile, //手机号
          num: this.mo, //金额
          product_edition:
            this.$route.params.id == 4 ? "自定义套餐" : this.taomsg.cname, //套餐名称
          self_built: this.$route.params.id == 4 ? "2" : "1", //是否是自建套餐  1否 2是
          p_id: this.$route.params.taomsg.id, //商品 id
          accredit: this.menuindex, // 授权方式  1短信授权、2上传授权书 3、生物授权
          set_id: this.setId, //具体产品信息 数组
          pay_type: this.payindex, //个人支付 1\2\3消耗数量   4金额 6个人支付
          l_id: "",
          is_insurance: this.checked == true ? "1" : "0", // 是否勾选了保险业务
          certId: this.certId, //学历证书编号
        })
        .then((res) => {
          if (JSON.parse(res.data).code != "8037") {
            if (this.menuindex == 3) {
              // this.$router.push("/qrcode");

              this.$router.push({
                name: "qrcode",
                params: {
                  msg: this.payindex == 6 ? "backcenter" : "backcenter2",
                  url: this.url,
                  phone: this.obj.mobile,
                  idNo: this.obj.idNo,
                  name: this.obj.name,
                },
              });
            } else if (this.menuindex == 1 && this.payindex == 6) {
              this.$router.push("/backsendsuccess");
            } else if (this.menuindex == 1 && this.payindex == 4) {
              this.$router.push("/backsendsuccess");
            }
          } else {
            this.$toast(JSON.parse(res.data).msg);
          }
        })
        .catch((res) => {});
    },
  },
};
</script>
<style scoped>
.temsclass {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  overflow: auto;
}
input {
  min-width: 5rem;
}
.qiyongclass > div:first-child {
  margin-right: 0.05rem;
}
.qiyongclass {
  display: flex;
  align-items: center;
}
input {
  text-align: right;
}
.xiangxi {
  display: flex;
  align-items: center;
  margin-left: 0.2rem;
}
.baoxian {
  display: flex;
  align-items: center;
}
.baoxianmoney {
  color: #f55613;
  font-size: 0.28rem;
  line-height: 0.33rem;
  font-weight: bold;
}
.labelclass {
  margin-left: 0.2rem;
  color: #616b80;
}
.inclass {
  border: none;
}
.dc {
  background-color: #ffffff;
}
.tipclass {
  color: #f55613;
  font-size: 0.18rem;
  line-height: 0.24rem;
  margin: 0.17rem 0;
  margin-left: 0.6rem;
}
.buclass {
  display: flex;
  justify-content: center;
  font-size: 0.32rem;
  border-radius: 0.8rem;
  overflow: hidden;
  margin-top: 0.24rem;
  margin-bottom: 0.23rem;
  font-weight: bold;
}
#bu1,
#bu2,
#bu3,
#bu4 {
  border: none;
  width: 45%;
  padding: 0.2rem 0;
}
#bu1 {
  background-color: #f55613;
  color: #ffffff;
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
#bu2 {
  background-color: rgba(245, 86, 19, 0.39);
  color: #f55613;
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}
#bu3 {
  background-color: rgba(245, 86, 19, 0.39);
  color: #f55613;
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
#bu4 {
  background-color: #f55613;
  color: #ffffff;
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}
.sq {
  background-color: rgba(245, 86, 19, 0.19);
  border: 1px dotted #f55613;
  margin: 0.23rem 0;
  padding: 0.2rem 0.43rem;
  font-size: 0.24rem;
  color: #f55613;
}
.writetipe {
  color: #333333;
  font-size: 0.3rem;
  font-weight: bold;
  display: flex;
  margin-left: 0.33rem;
  margin-bottom: 0.31rem;
}
.writetipe img {
  width: 0.11rem;
  margin-right: 0.23rem;
}
.pcla {
  display: flex;
  justify-content: space-between;
  margin: 0 0.3rem;
  padding: 0.21rem 0;
  border-bottom-color: #ebeef5;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  align-items: center;
}
.pcla > p:first-child {
  color: #616b80;
  font-size: 0.28rem;
  font-weight: bold;
}
.pcla > p:nth-child(2) {
  color: #000000;
}
.pcla span {
  color: #e14549;
}

.sp {
  background-color: rgba(245, 86, 19, 0.19);
  margin: 0.23rem 0;
  padding: 0.2rem 0.43rem;
  font-size: 0.24rem;
  color: #f55613;
}

.payclass {
  display: flex;
  justify-content: space-between;
  padding: 0 0.3rem;
}
.payclass div:first-child {
  color: #616b80;
  font-size: 0.24rem;
  margin-top: 0.2rem;
}
.payclass div:first-child span {
  color: #e14549;
}
.payclass div:nth-child(2) {
  margin-top: 0.3rem;
}
#mepay {
  border: none;
  color: #ffffff;
  background-color: #1677ff;
  font-size: 0.24rem;
  padding: 0.11rem 0.51rem;
  margin: 0 0.16rem;
  border: 1px solid #1677ff;
}
#qipay {
  border: none;
  border: 1px solid #1677ff;
  background-color: #ffffff;
  color: #1677ff;

  font-size: 0.24rem;
  padding: 0.11rem 0.51rem;
}
.selectclass {
  margin-top: 0.58rem;
  margin-bottom: 0.41rem;
}
.selectclass + p {
  color: #616b80;
  font-size: 0.3rem;
  margin-bottom: 0.29rem;
  font-weight: bold;
  margin-left: 0.33rem;
}
.sfcl div {
  margin: 0.33rem;
  background-color: #fee0d3;
  padding: 0.32rem 0.67rem;
  font-size: 0.32rem;
  color: #f55613;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  border-radius: 10px;
}
.sfcl .selecl {
  background-color: #f76729;
  color: #ffffff;
}
.moneypay {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 -0.04rem 0.08rem #f5f7fa;
}
.moneypay div {
  display: flex;
  align-items: center;
}
.moneypay div p {
  color: #ff5a47;
}
.moneypay div p:first-child {
  font-size: 0.3rem;
  margin-left: 0.67rem;
}
.moneypay div p:nth-child(2) {
  font-size: 0.5rem;
}
.moneypay {
  position: fixed;
  bottom: 0;
  height: 1.51rem;
  width: 100%;
}
#fqclass {
  margin-right: 0.67rem;
  background-color: #f55613;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.26rem;
  padding: 0.13rem 0.37rem;
  border: none;
  border-radius: 5px;
}
.proclass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.24rem;
  margin-bottom: 0.12rem;
}
.pro2cl {
  display: flex;
  color: #616b80;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.6rem;
}
.pro2cl > p {
  font-size: 0.24rem;
}
.pro2cl > div p {
  font-size: 0.24rem;
}
.pro2cl div {
  display: flex;
  align-items: center;
}
.proclassall {
  padding: 0.3rem;
  font-weight: 500;
}
.proclass p span:first-child {
  font-size: 0.28rem;
  font-weight: 500;
  margin-right: 0.1rem;
}
.proclass p span:nth-child(2) {
  font-size: 0.36rem;
  font-weight: 500;
}
.proclass div:first-child label {
  font-weight: 500;
}
.proclass div:first-child input {
  margin-right: 0.2rem;
}
</style>
